import en from 'assets/locales/en.json'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

export function initI18n(): void {
  const resources = {
    en: { translation: en },
  }

  i18n
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      compatibilityJSON: 'v4',
      resources,
      fallbackLng: 'en',
      debug: true,

      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
    })
}
