import { Buffer } from 'buffer/'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { initI18n } from 'services/i18n'

initI18n()
window.Buffer = window.Buffer || Buffer

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

;(async () => {
  const app = await import('./App')

  root.render(
    <React.StrictMode>
      <app.default />
    </React.StrictMode>,
  )
})()
